<template>
  <div>
    <StreamTimeline :stream-entries="streamEntries" />
  </div>
</template>

<script>

import StreamTimeline from '@/components/stream/StreamTimeline'

export default {
    name: 'Stream',
    components: {
        StreamTimeline
    },
    data() {
        return {
            streamEntries: null
        }
    },
    mounted() {
        this.$store.dispatch('getStreamEntries').then(streamEntries => {
            this.streamEntries = streamEntries
        }).catch(() => {

        })
    }
}
</script>