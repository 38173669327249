<template>
  <v-timeline
    dense
    clipped
  >
    <template v-for="(streamEntry, index) in streamEntries">
      <v-timeline-item
        fill-dot
        class="mb-3"
        color="green"
        small
        :key="index"
      >
        <v-card>
          <v-card-title>{{ streamEntry.message }}</v-card-title>
          <v-card-subtitle>{{ streamEntry.timestampInMillis | moment('YYYY/MM/DD HH:mm') }}</v-card-subtitle>
        </v-card>
      </v-timeline-item>
    </template>
  </v-timeline>
</template>

<script>
export default {
    name: 'StreamTimeline',
    props: ['streamEntries']
}
</script>